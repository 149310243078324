import Head from 'next/head';
import { GetServerSideProps, NextPage } from 'next/types';
import { useTranslation } from 'next-i18next';
import React from 'react';
import Introduction from '@components/Introduction/Introduction';
import WebPageLayout from '@components/layouts/WebPageLayout';
import { getSiteName } from '@libs/getSiteName';
import { stringOrError } from '@libs/stringUtils';
import { initializeApolloFromSsrRequest } from '../../apollo-client';
import { getGlobalServerSideProps } from '../../helpers/globalServerSide';

const siteName = getSiteName();

const IntroductionPage: NextPage = () => {
  const { t } = useTranslation('front');

  return (
    <WebPageLayout>
      <Head>
        <title>
          {t('INTRODUCTION_TITLE')} | {siteName}
        </title>
      </Head>
      <Introduction />
    </WebPageLayout>
  );
};

export default IntroductionPage;

export const getServerSideProps: GetServerSideProps = async ({ locale: localeOrUndefined, req }) => {
  const locale = stringOrError(localeOrUndefined);
  const { apolloClient } = initializeApolloFromSsrRequest(req, locale);

  const globalServerSideProps = await getGlobalServerSideProps(apolloClient, req, locale);

  return {
    props: {
      ...globalServerSideProps,
      initialApolloState: apolloClient.cache.extract(),
    },
  };
};
